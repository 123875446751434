<template>
    <div>
        <v-btn :loading="loading.url" small class="ml-2 lighten-2" v-if="updateMode && !listMode" @click.stop="printLetterOfCreditAdvice()">
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
        <v-list-item class="font-sm" @click.stop="printLetterOfCreditAdvice()" v-if="updateMode && listMode">
            <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.url"/>
            <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
            <span>{{ $t('message.pdf') }}</span>
        </v-list-item>
<!--        <PrintLcaBlob-->
<!--            :lca-id="letterOfCreditAdviceId"-->
<!--            :key="updateLetterOfCreditAdviceKey"-->
<!--            @pdf-uploading="letterOfCreditAdviceUploading"-->
<!--            @pdf-uploaded="letterOfCreditAdviceUploaded"-->
<!--            @pdf-not-uploaded="letterOfCreditAdviceNotUploaded"-->
<!--            v-if="hasLetterOfCreditAdvice"-->
<!--        />-->
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
// import {log, makeId} from "Helpers/helpers";

// const PrintLcaBlob = () => import("Views/v1/lcas/PrintLcaBlob");
export default {
    name: "PrintLetterOfCreditAdviceButton",
    props: ['letterOfCreditAdviceId','updateMode','listMode'],
    // components: {PrintLcaBlob},
    data() {
        return {
            // currentLetterOfCreditAdvicePdf: null,
            // hasLetterOfCreditAdvice: false,
            loading: {
                // letterOfCreditAdvice: false,
                url: false
            },
            // updateLetterOfCreditAdviceKey: 1
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getPrintPdfUrl() {
            return new Promise((resolve, reject) => {
                api
                    .get("/print-url/lca/" + this.letterOfCreditAdviceId)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        printLetterOfCreditAdvice() {
            this.loading.url = true;
            this.getPrintPdfUrl()
                .then(response => {
                    if(response.data.status == 'success') {
                        let tab = window.open(response.data.url, '_blank');
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                    }
                    this.loading.url = false;
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.pdfError'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        })
                    this.loading.url = false;
                })
        }
        // getExistingPdf() {
        //     return new Promise((resolve, reject) => {
        //         api
        //             .get("/lcas/" + this.letterOfCreditAdviceId, {
        //                 params: {
        //                     fields: [
        //                         'lcas.location AS lcas__location'
        //                     ]
        //                 }
        //             })
        //             .then(response => {
        //                 const letterOfCreditAdvice = response.data.data[0]
        //                 if(letterOfCreditAdvice){
        //                     this.currentLetterOfCreditAdvicePdf = letterOfCreditAdvice.Lca.location
        //                 }
        //                 resolve('success')
        //             })
        //             .catch(error => {
        //                 reject(error);
        //             });
        //     })
        // },
        // letterOfCreditAdviceNotUploaded() {
        //     this.loading.letterOfCreditAdvice = false
        //     this.hasLetterOfCreditAdvice = false
        //     this.$toast.error(this.$t('message.errors.couldNotPrint'),
        //         {
        //             classes: ['icon-float-left'],
        //             icon: 'error_outline'
        //         }
        //     )
        // },
        // letterOfCreditAdviceUploaded(url) {
        //     this.currentLetterOfCreditAdvicePdf = url
        //     this.loading.letterOfCreditAdvice = false
        //     this.viewFile(this.currentLetterOfCreditAdvicePdf)
        //     this.hasLetterOfCreditAdvice = false
        // },
        // letterOfCreditAdviceUploading() {
        //     this.loading.letterOfCreditAdvice = true
        // },
        // printLetterOfCreditAdvice() {
        //     this.loading.letterOfCreditAdvice = true
        //     this.getExistingPdf()
        //         .then(()=>{
        //             if(this.currentLetterOfCreditAdvicePdf != null){
        //                 this.viewFile(this.currentLetterOfCreditAdvicePdf)
        //                     .then(() => this.loading.letterOfCreditAdvice = false)
        //                     .catch(() => this.loading.letterOfCreditAdvice = false)
        //             } else {
        //                 this.hasLetterOfCreditAdvice = true
        //             }
        //         })
        //         .catch((error) => {
        //             log(error)
        //             this.loading.letterOfCreditAdvice = false
        //         })
        // },
        // viewFile(file) {
        //     return new Promise((resolve,reject) => {
        //         try {
        //             let tab = window.open(file + '?' + makeId(5), '_blank')
        //             tab.name = 'tab_' + Math.floor(Math.random() * 10000)
        //             this.openedTabs.push(tab.name)
        //             resolve('done')
        //         } catch(error) {
        //             reject('error')
        //         }
        //     })
        // }
    }
}
</script>

<style scoped>

</style>